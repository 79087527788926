import { CSSProperties, ReactNode } from "react";

import { Layout } from "antd";
import styled from "styled-components";

import PageLoader from "components/loading";

interface BaseLayoutProps {
  children?: ReactNode;
  style?: CSSProperties;
  loading?: boolean;
  id?: string;
}

export const ScrollableContainer = styled.div`
  height: 94vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const BaseLayout = ({ children, style, loading, id }: BaseLayoutProps) => {
  return (
    <Layout id={id} style={style || {}}>
      {loading && <PageLoader />}
      {children}
    </Layout>
  );
};

export default BaseLayout;
