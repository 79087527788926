import React, { useState } from "react";

import { t, Trans } from "@lingui/macro";
import { Form, Input, message, Typography } from "antd";
import axios from "axios";
import Link from "next/link";
import { useRouter } from "next/router";

import { AuthContainer, ShowPassword } from "components/auth";
import Button from "components/uikit/button";
import { useAppContext } from "contexts/app";
import PublicLayout from "layouts/public";
import { withAuthenticationRedirect, withAuthenticationRedirectHandler } from "utils/session-utils";
import { httpStatusCodes } from "utils/status-codes-utils";

const { Title, Text } = Typography;

const Login = ({ isAuthenticated }) => {
  const [submitting, setSubmitting] = useState(false);
  const { httpClient, appData } = useAppContext();

  const router = useRouter();

  const onFinish = async (values) => {
    setSubmitting(true);

    httpClient
      .post(`/authenticate/`, values)
      .then((response) => {
        if (response.status === httpStatusCodes.OK) {
          axios
            .post(`/api/session`, {
              token: response.data.token,
              account: response.data.account,
              user: response.data.user,
            })
            .then(() => {
              if (response.status === httpStatusCodes.OK) {
                router.push("/dashboard");
                setSubmitting(false);
              }
            })
            .catch((e) => {
              message.error(e?.response.data);
              setSubmitting(false);
              return;
            });
        }
      })
      .catch((e) => {
        if (e?.response?.data?.non_field_errors) {
          message.error(e?.response?.data?.non_field_errors[0]);
        } else {
          message.error(e?.message);
        }
      });
    setSubmitting(false);
  };

  return (
    <PublicLayout language={appData.language} isAuthenticated={isAuthenticated}>
      <AuthContainer>
        <div className="login-container">
          <Title className="login-title">
            <Trans>Log in</Trans>
          </Title>
          <div className="login-no-account">
            <Text>
              <Trans>Don’t have an account?</Trans>
            </Text>
            <Link href="/signup">
              <a style={{ marginLeft: 4 }}>
                <Text strong>
                  <Trans>Sign up</Trans>
                </Text>
              </a>
            </Link>
          </div>
          <Form size="large" onFinish={onFinish}>
            <Form.Item name="username" rules={[{ required: true, message: t`Please input your email!` }]}>
              <Input placeholder={t`Email`} data-cy={"email"} />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: t`Please input your password!` }]}>
              <Input.Password
                iconRender={(x) =>
                  x ? (
                    <ShowPassword>
                      <Trans>HIDE</Trans>
                    </ShowPassword>
                  ) : (
                    <ShowPassword>
                      <Trans>SHOW</Trans>
                    </ShowPassword>
                  )
                }
                placeholder={t`Password`}
                data-cy={"password"}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={submitting}
              block
              data-cy={"login-button"}
              label={t`Login`}
            />
          </Form>
          <div style={{ textAlign: "center", marginTop: 24 }}>
            <Link href="/password/forgot">
              <a>
                <Text strong>
                  <Trans>Forgot your password?</Trans>
                </Text>
              </a>
            </Link>
          </div>
        </div>
      </AuthContainer>
    </PublicLayout>
  );
};

export const getServerSideProps = withAuthenticationRedirect(withAuthenticationRedirectHandler);
export default Login;

// TODO convert to shared component.
