import styled from "styled-components";

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;

  .login {
    &-container {
      width: 80%;
      max-width: 400px;
    }

    &-forgot-password {
      text-align: center;
      color: #c4c4c4;
      cursor: pointer;
    }

    &-title {
      font-weight: 800 !important;
      font-size: 40px !important;
      line-height: 48px !important;
      /* identical to box height */

      text-align: center;
      letter-spacing: 0.02em;
    }

    &-no-account {
      display: flex;
      justify-content: center;
      margin: 16px 0;
    }

    &-text {
      font-style: normal;
      font-size: 12px;
      line-height: 20px;
      text-align: center;

      a {
        font-weight: 600;
      }

      &-bold {
        margin-left: 5px;
        font-style: normal;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        font-weight: 600;
        cursor: pointer;
      }
    }

    &-button {
      width: 100%;
      background: #60fa67;
      border-radius: 3px;
      height: 48px;
      border: 0;
      margin-top: 10px;
      cursor: pointer;

      &:hover {
        background: #94ff99;
      }
    }
  }
`;

const ShowPassword = styled.div`
  color: #60fa67 !important;

  :hover {
    cursor: pointer;
  }
`;

export { AuthContainer, ShowPassword };
