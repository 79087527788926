import { ReactNode } from "react";

import { CaretDownOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import { Button, Col, Layout, message, Row, Select, Space, Typography } from "antd";
import axios from "axios";
import { isEmpty, map, upperCase } from "lodash";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import PageLoader from "components/loading";
import useRedirect from "hooks/redirect";
import BaseLayout from "layouts/base";

const { Content, Header } = Layout;
const { Option } = Select;
const { Text } = Typography;

const LANGUAGES = process.env.NEXT_PUBLIC_LANGUAGES;

interface PublicLayoutProps {
  children: ReactNode;
  isAuthenticated?: boolean;
  language: string;
}

const PublicLayout = ({ children, isAuthenticated, language }: PublicLayoutProps) => {
  const router = useRouter();
  const shouldRedirect = router.query["redirect"] !== "false";

  useRedirect(shouldRedirect && isAuthenticated, "/dashboard");

  if (shouldRedirect && !isEmpty(isAuthenticated)) return <PageLoader />;
  const languages = LANGUAGES.split(",");

  const languageChange = async (language) => {
    try {
      await axios.post("/api/language", { language });
      location.reload();
    } catch (error) {
      message.error("Changing languages has failed!");
    }
  };

  return (
    <BaseLayout>
      <Header style={{ backgroundColor: "#ffffff", padding: 0 }}>
        <Row wrap={false}>
          <Col flex="none">
            <div style={{ padding: "25px 40px" }}>
              <Link href="https://www.gsmtasks.com">
                <a>
                  <Image src="/logos/dark.svg" alt="GSMtasks logo" width="178" height="32" />
                </a>
              </Link>
            </div>
          </Col>
          <Col flex="auto" style={{ textAlign: "right" }}>
            <Space style={{ padding: "25px 40px", lineHeight: 1 }}>
              <Link href="/login">
                <Button size="large" type="text">
                  <Trans>Login</Trans>
                </Button>
              </Link>
              <Link href="/signup">
                <Button size="large" type="default" style={{ border: "2px solid #60FA67" }}>
                  <Text strong>
                    <Trans>Free sign up</Trans>
                  </Text>
                </Button>
              </Link>
              <Select
                defaultValue={language}
                size="large"
                bordered={false}
                suffixIcon={<CaretDownOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />}
                style={{ width: 65 }}
                onChange={(value) => {
                  languageChange(value);
                }}
              >
                {map(languages, (l) => (
                  <Option value={l} key={l}>
                    {upperCase(l)}
                  </Option>
                ))}
              </Select>
            </Space>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Content style={{ padding: 24, boxSizing: "border-box", minHeight: "calc(100vh - 54px)" }}>{children}</Content>
      </Layout>
    </BaseLayout>
  );
};

export default PublicLayout;
